//
//
//
//
//
//

export default {
  props: {
    value: {
      type: Boolean,
      required: true
    },
    invalid: {
      type: [String, undefined],
      default: ''
    }
  },
  methods: {
    handleInput(event) {
      const value = event.target.checked;
      this.$emit('input', value);
    }
  }
};
