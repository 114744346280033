//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    value: Boolean,
    disabled: { type: Boolean, default: false }
  }
};
