//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import VueSelect from 'vue-select';
import FormInputTitle from './form-input-title.vue';

export default {
  components: {
    FormInputTitle,
    VueSelect
  },
  props: {
    value: {
      type: [String, Number],
      default: ''
    },
    options: {
      type: Array,
      required: true
    },
    loading: Boolean,
    disabled: {
      type: Boolean,
      default: false
    },
    invalid: {
      type: [String, undefined],
      default: ''
    }
  },
  computed: {
    optionSelected() {
      if (!this.value) return null;

      return this.options.find(o => o.value === this.value);
    }
  },
  methods: {
    handleInput(option) {
      if (option === null) {
        this.$emit('input', '');
        return;
      }

      this.$emit('select', option);

      this.$emit('input', option.value);
    }
  }
};
