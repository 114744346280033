//
//
//
//
//
//
//

export default {
  props: {
    dark: Boolean
  }
};
