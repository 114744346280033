//
//
//
//
//
//
//
//

export default {
  props: {
    value: [String, Number],
    storedValue: [String, Number],
    isSelected: Boolean
  },
  methods: {
    emitChange() {
      const { value } = this;

      this.$emit('select', { value });
    }
  }
};
