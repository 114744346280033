import { render, staticRenderFns } from "./form-desktop-box.vue?vue&type=template&id=68086261&scoped=true&"
var script = {}
import style0 from "./form-desktop-box.vue?vue&type=style&index=0&id=68086261&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "68086261",
  null
  
)

export default component.exports